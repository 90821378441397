import { Checkbox } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import DatePicker from '../../components/DatePicker/DatePicker';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { fetchDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { fetchShippers } from '../../store/actions/shippers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { createTrip } from '../../store/actions/trips.actions';
import { ExtendedDeliveryOrder, ExtendedPackageGroup, RootState, TripRequest } from '../../store/config/types';
import { Shipper } from '../../store/config/types/shippers.types';
import schemas from '../../utils/schemas';

interface TripValues {
  date: string;
}

const mapStateToProps = (state: RootState) => ({
  trips: state.trips,
  deliveryOrders: state.deliveryOrders,
  shippers: state.shippers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  createTrip: (tripRequest: TripRequest) => dispatch(createTrip(tripRequest)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  fetchDeliveryOrders: () => dispatch(fetchDeliveryOrders()),
  fetchShippers: () => dispatch(fetchShippers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TripsForm({
  trips,
  deliveryOrders,
  shippers,
  createTrip,
  setToast,
  fetchDeliveryOrders,
  fetchShippers,
}: PropsFromRedux) {
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deliveryOrderIds, setDeliveryOrderIds] = useState<number[]>([]);

  const handleGoBack = () => history.push('/trips');

  useEffect(() => {
    if (
      !deliveryOrders.loadingDeliveryOrders &&
      !deliveryOrders.deliveryOrders &&
      !deliveryOrders.deliveryOrdersErrorMessage
    ) {
      fetchDeliveryOrders();
    }
  }, [
    fetchDeliveryOrders,
    deliveryOrders.deliveryOrders,
    deliveryOrders.deliveryOrdersErrorMessage,
    deliveryOrders.loadingDeliveryOrders,
  ]);

  useEffect(() => {
    if (
      !shippers.loadingShippers &&
      !shippers.shippers &&
      !shippers.shippersFetched &&
      !shippers.shippersErrorMessage
    ) {
      fetchShippers();
    }
  }, [
    fetchShippers,
    shippers.loadingShippers,
    shippers.shippers,
    shippers.shippersFetched,
    shippers.shippersErrorMessage,
  ]);

  useEffect(() => {
    if (submitting) {
      if (trips.createTripSuccess) {
        setSubmitting(false);
        setToast('Trip successfully created', 'success');
        history.push('/trips');
      } else if (trips.createTripErrorMessage) {
        setSubmitting(false);
        setToast(`An error has ocurred while creating trip: ${trips.createTripErrorMessage}`, 'danger');
      }
    }
  }, [submitting, trips.createTripSuccess, setSubmitting, setToast, history, trips.createTripErrorMessage]);

  const submitForm = (values: TripValues) => {
    if (deliveryOrderIds.length > 0) {
      const tripRequest: TripRequest = {
        startsAt: moment(values.date).format('YYYY-MM-DD'),
        deliveryOrderIds,
      };

      setSubmitting(true);
      createTrip(tripRequest);
    } else {
      setToast('You must select at least one delivery order per trip', 'danger');
    }
  };

  const toggleValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    const index = deliveryOrderIds.indexOf(value);

    if (index > -1) {
      setDeliveryOrderIds([...deliveryOrderIds.filter((id: number) => id !== value)]);
    } else {
      setDeliveryOrderIds([...deliveryOrderIds, value]);
    }
  };

  return (
    <div className="trip-form">
      <Card profile>
        <CardHeader color="primary" className="trip-header">
          <h4>New trip</h4>
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{ date: moment().format('YYYY-MM-DD') }}
            validationSchema={schemas.TripSchema}
            onSubmit={submitForm}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <DatePicker
                      label="Date"
                      value={values.date}
                      onChange={(e) =>
                        moment(e.target.value) >= moment().startOf('day') && setFieldValue('date', e.target.value)
                      }
                    />
                  </GridItem>
                </GridContainer>
                <h3 className="section-title">Delivery orders for selected date</h3>
                <div className="delivery-orders-container">
                  <div className="delivery-orders">
                    <div className="option title">
                      <GridItem xs={2}>
                        <b>Delivery order ID</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <b>Warehouse</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <b>Shipper</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <b>Number of packages</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <b>Number of recipients</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <b>Add to trip</b>
                      </GridItem>
                    </div>
                    {deliveryOrders.deliveryOrders
                      ?.filter(
                        (deliveryOrder: ExtendedDeliveryOrder) =>
                          moment.utc(deliveryOrder.deliveryExpectedAt).format('YYYY-MM-DD') === values.date &&
                          !!deliveryOrder.packageGroups.length &&
                          deliveryOrder.packageGroups.every(
                            (packageGroup: ExtendedPackageGroup) =>
                              packageGroup.packageGroupStatus === 'Order submitted',
                          ),
                      )
                      .map((deliveryOrder: ExtendedDeliveryOrder) => {
                        const deliveryOrderShipper = shippers.shippers?.find(
                          (shipper: Shipper) => shipper.shipperId === deliveryOrder.warehouse.shipperId,
                        );

                        return (
                          <div key={deliveryOrder.deliveryOrderId} className="option">
                            <GridItem xs={2}>{deliveryOrder.deliveryOrderId}</GridItem>
                            <GridItem xs={2}>{deliveryOrder.warehouse.name}</GridItem>
                            <GridItem xs={2}>
                              {deliveryOrderShipper && !!deliveryOrderShipper?.companyName
                                ? deliveryOrderShipper?.companyName
                                : '-'}
                            </GridItem>
                            <GridItem xs={2}>{deliveryOrder.packagesAmount}</GridItem>
                            <GridItem xs={2}>{deliveryOrder.numberOfRecipients}</GridItem>
                            <GridItem xs={2}>
                              <Checkbox
                                checked={
                                  deliveryOrderIds.find((id: number) => id === deliveryOrder.deliveryOrderId) !==
                                  undefined
                                }
                                value={deliveryOrder.deliveryOrderId}
                                onChange={toggleValue}
                              />
                            </GridItem>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        Create trip
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
}

export default connector(TripsForm);
