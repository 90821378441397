import axios from './axios';
import { Trip, CreateTripOfferRequest, TripRequest, Stop } from '../store/config/types/trips.types';

async function fetchTrips(): Promise<Trip[] | undefined> {
  const res = await axios.get('/trips');
  return res.data.data;
}

async function fetchTripStops(tripId: number): Promise<Stop[] | undefined> {
  const res = await axios.get(`trips/${tripId}/stops/?mapTripStopWithLocation=true`);
  return res.data.data;
}

async function createTrip(tripRequest: TripRequest): Promise<Trip[] | undefined> {
  try {
    const res = await axios.post('/trips', tripRequest);
    return res.data.data;
  } catch (err: any) {
    throw err?.response?.data?.message;
  }
}

async function createTripOffer(
  tripId: number,
  createTripOfferRequest: CreateTripOfferRequest,
): Promise<Trip | undefined> {
  const res = await axios.post(`/trips/${tripId}/offer`, createTripOfferRequest);
  return res.data.data;
}

async function deleteTripOffer(tripOfferId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/trips/offer/${tripOfferId}`);
  return res.data.data;
}

async function deleteAllTrips(): Promise<boolean | undefined> {
  const res = await axios.delete('/trips/delete-all');
  return res.data.data;
}

async function resetStateAllTrips(): Promise<boolean | undefined> {
  const res = await axios.delete('/trips/reset-all');
  return res.data.data;
}

export const tripsService = {
  fetchTrips,
  fetchTripStops,
  createTrip,
  createTripOffer,
  deleteTripOffer,
  deleteAllTrips,
  resetStateAllTrips,
};
